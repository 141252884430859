body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  background: black!important;
}

[data-theme=dark],
.theme-dark {
  background: black!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.label-default {
  color: #d1a41a;
}

.title-leagues {
  color: #d1a41a;
  /*text-align: center;
  border-bottom: 1px solid #d1a41a;
  box-shadow: 0px 3px 14px 0px #d1a41a;*/
}

.center {
  margin: auto;
}

.button.is-primary.is-outlined.custom-button {
  border-color: #d1a41a;
  color: #d1a41a;
}

.box-matches {
  flex-wrap: wrap;
}

.play-button {
  color: green
}

html, body, #app {
  height: 100%;
}

#app {
  min-height: 100%;
}

.matchCard {
  cursor: pointer;
}

.input-date-picker {
  width: 330px;
  margin: 0 auto;
  display: flex;
}

input.input-date-picker {
  color: #d1a41a;
  background: white;
}

button.is-link.is-outlined.default-button-player {
  color: #d1a41a;
  border-color: #d1a41a;
}

.default-center-div {
  width: 135px;
  margin: auto;
  align-content: center;
  text-align: center;
}

.mid-center-div {
  width: 250px;
  margin: auto;
}

.big-center-div {
  width: 400px;
  margin: auto;
}

.matches.tabs.is-toggle li.is-active a {
  background-color: #d1a41a;
  border-color: #d1a41a;
}

.panel-heading {
  background-color: #28272E;
}


.card .card-content {
  padding: 15px;
  border-top: 2px solid #d1a41a;
}


.blink-dot.dot-live {
  -webkit-animation: blink-live .7s infinite alternate;
  animation: blink-live .7s infinite alternate;
}

.b-btn-live {
  color: #f1516e;
  border: 1px solid #f1516e;
  padding: 0 8px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
}

.b-btn-live .blink-dot {
  max-width: 6px;
  min-width: 6px;
  height: 6px;
}

.blink-dot {
  -webkit-animation: blink-default .7s infinite;
  animation: blink-default .7s infinite;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.b-btn-live {
  background-color: rgba(255, 112, 137, .3);
  border: none;
  border-radius: 24px;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 8px;
}

@keyframes blink-live {
  0% {
      background-color: #f1516e
  }

  50% {
      background-color: #fff
  }

  to {
      background-color: #f1516e
  }
}